@import '../../../../styles/customMediaQueries.css';

.root {
  background-color: var(--colorBlack);
  width: 100%;
}

.footer {
  gap: 32px;
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: -1px;

  @media (--viewportLarge) {
    padding: 105px 75px 31px 75px;
    margin-top: unset;
    position: relative;
    background-color: var(--colorBlack);
    gap: 70px;
  }
}

.container {
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media (--viewportLarge) {
    flex-direction: row;
    gap: 12px;
    gap: 10%;
  }
}

.logoLink {
  display: inline-block;
  padding: 0;

  &:hover {
    text-decoration: none;
  }
}

.logoWrapper {
  width: 282px;
  margin: 0;
  height: 20px;
}

.logoImage {
  height: auto;
  width: auto;
  max-width: 100%;
  object-fit: contain;
  object-position: center;

  @media (--viewportLarge) {
    max-width: min(100%, 280px);
  }
}

.links {
  width: fit-content;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.link {
  color: var(--colorWhite);
  transition: var(--transitionStyleButton);
  font-size: 14px;
  letter-spacing: 2.4px;
  white-space: nowrap;
  text-align: start;

  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }
}

.copyright {
  padding: 0;
  font-size: 12px;
  line-height: 21px;
  letter-spacing: 1.1px;
  color: rgba(255, 255, 255, 1);
  opacity: 0.5;
  text-align: center;
  font-weight: 300;
  margin-bottom: 0;
}